import axios from 'axios';

axios.interceptors.response.use(
    (response) => {
        if (window.location.hash === '/#/login') {
            //window.location = '/#/two-factor-authentication';
        }
        return response;
    },
    async (error) => {
        if (window.location.hash.includes('#/forgot-password')) {
            return;
        } else if (error.response.data.statusCode === 401 && window.location.hash !== '#/login') {
            window.location = '/#/login';
        }
        return Promise.reject(error.response.data);
    },
);

const apiLibrary = {
    get(address, params) {
        return axios.get(`${address}`, params);
    },
    post(address, data) {
        return axios.post(`${address}`, data);
    },
    delete(address) {
        return axios.delete(`${address}`);
    },
    patch(address, data) {
        return axios.patch(`${address}`, data);
    },
    put(address, data) {
        return axios.put(`${address}`, data);
    },
};

export default apiLibrary;
