import { createStore, combineReducers } from 'redux';

const initialState = {
    sidebarShow: true,
};

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest };
        default:
            return state;
    }
};

const editModal = (state = false, { type, ...rest }) => {
    switch (type) {
        case 'display_edit':
            return { state: true, ...rest };
        case 'close_edit':
            return { state: false, ...rest };
        default:
            return state;
    }
};

const deleteModal = (state = false, { type, ...rest }) => {
    switch (type) {
        case 'display_delete':
            return { state: true, ...rest };
        case 'close_delete':
            return { state: false, ...rest };
        default:
            return state;
    }
};

const newContactModal = (state = false, { type, ...rest }) => {
    switch (type) {
        case 'display_new_contact':
            return { state: true, ...rest };
        case 'close_new_contact':
            return { state: false, isCreatedNew: false, ...rest };
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    navigation: changeState,
    edit: editModal,
    delete: deleteModal,
    new: newContactModal,
});

const store = createStore(rootReducer);
export default store;
