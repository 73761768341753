const de = {
    //global
    id: 'ID',
    edit: 'Edit',
    toEdit: 'Edit',
    save: 'Save',
    loading: 'Loading...',
    create: 'Create',
    create_new: 'Create New',
    cancel: 'Cancel',
    search: 'Search ',
    confirm: 'Confirm',
    delete: 'Delete',
    date: 'Date',
    type: 'Type',
    submit: 'Submit',
    action: 'Action',
    regards: 'Regards',
    female: 'Mrs.',
    male: 'Mr.',
    other: 'Other',
    private_section: 'SECTION FOR PRIVATE DATA',
    business_section: 'SECTION FOR BUSINESS DATA',
    switzerland: 'Switzerland',
    usa: 'USA',
    logout: 'Logout',
    all: 'All',
    home: 'Home',
    dashboard: 'Dashboard',
    no_results: 'No Results',
    social: 'Social',
    list: 'List',
    map: 'Map',
    table: 'Tableau',
    //users
    role: 'Role',
    users: 'Users',
    username: 'User Name',
    password: 'Password',
    new_user: 'New User',
    delete_user: 'Are you sure you want to delete this user?',
    permanent_delete: 'User will be permanently deleted.',
    no_users: 'No users found',
    search_users: 'Search users',
    //options
    selection: 'Selection...',
    group_selection: 'Group selection...',
    green: 'Green',
    blue: 'Blue',
    yellow: 'Yellow',
    red: 'Red',
    //map
    prospects: 'Prospects',
    prospect: 'Prospect',
    former_client: 'Former Client',
    //montly performance
    latest_orders: 'Latest Orders',
    mp_sm: 'Montly Performance Social Media',
    //market
    market: 'Market',
    clients: 'Clients',
    client: 'Client',
    cy_py: 'CY/PY', // cy - current year, py - past year
    trend: 'Trend',
    ytd_units: 'YTD Units',
    py: '(PY)',
    cy: '(CY)',
    py_vs_cy: 'PY vs CY',
    sp_g: 'H-G',
    mtd: 'MTD',
    ytd: 'YTD',
    lo_7days: 'Last order in past 7 days',
    lo_14days: 'Last order in past 14 days',
    no_14days: 'No orders in past 14 days',
    //contact
    connections: 'Direct reports',
    connected_to: 'Reports to',
    peers: 'Peers',
    user: 'User',
    gender: 'Salutation',
    nationality: 'Nationality',
    specialty: 'Specialty',
    function: 'Function',
    unit: 'Unit',
    titles: 'Titles',
    contact: 'Contact',
    contacts: 'Contacts',
    hospital_id: 'Hospital ID',
    first_name: 'First name',
    last_name: 'Last name',
    birthday: 'Birthday',
    business_phone: 'Business',
    private_phone: 'Private',
    phone: 'Phone',
    mobile: 'Mobile',
    business_email: 'Business',
    private_email: 'Private',
    private: 'Private',
    business: 'Business',
    city: 'City',
    street: 'Street',
    street_num: 'Number',
    country: 'Country',
    state: 'State',
    type_id: 'Type',
    email: 'Email',
    postal_code: 'ZIP',
    tags: 'Tags',
    position: 'Position',
    linkedin_link: 'Linkedin',
    instagram_link: 'Instagram',
    facebook_link: 'Facebook',
    new_contact: 'New contact',
    new_surgeon: 'New surgeon',
    export_contacts: 'Export Contacts',
    search_contact: 'Search contacts',
    assistant: 'Assistant',
    key: 'Key',
    reports: 'Reports',
    report: 'Report',
    physician: 'Physician',
    filter: 'Filter',
    business_address: 'Bussiness Address',
    private_address: 'Private Address',
    get_directions: 'Get directions',
    /// contact-function enums
    surgeon: 'Surgeon',
    nurse: 'Nurse',
    hospital_admin: 'Hospital Admin',
    supplier: 'Supplier',
    employee: 'Employee',
    worker: 'Worker',
    //contact-tabs
    mails: 'Mails',
    notes: 'Notes',
    details: 'Details',
    delete_contact: 'Are you sure you want to delete this contact?',
    delete_hospital: 'Are you sure you want to delete this hospital?',
    //group
    group: 'Hospital group',
    groups: 'Hospital groups',
    color_group: 'Group',
    created_at: 'Created At',
    updated_at: 'Updated At',
    deleted_at: 'Deleted At',
    name: 'Name',
    new_group: 'New hospital group',
    search_group: 'Search hospital group',
    //hospitals
    hospital: 'Hospital',
    company: 'Company',
    hospitals: 'Hospitals',
    fellowship: 'Fellowship',
    fellowship_label: 'Fellowship',
    residency: 'Residency',
    medical_school: ' Medical School',
    undergraduate: 'Undergraduate',
    firma: 'Company name',
    group_id: 'Group ID',
    address_4: 'Additional Address',
    address: 'Address',
    zip: 'ZIP',
    ctelex: 'Email',
    currency: 'Currency',
    new_hospital: 'New hospital',
    search_hospital: 'Search hospital',
    no_hospital: 'No hospitals',
    cmtc: 'Cmtc',
    cmtcl: 'Cmtcl',
    cmsb: 'Cmsb',
    hospital_group: 'HG/BG',
    //orders
    order: 'Order',
    orders: 'Orders',
    amount: 'Amount',
    last_orders: 'Last orders',
    product: 'Product',
    products: 'Products',
    new_order: 'New order',
    quantity: 'Quantity',
    //reports
    visit_reports: 'Visit reports',
    new_report: 'New report',
    status: 'Status',
    created: 'Created',
    successfully_created: 'Report created successfully!',
    // jira tasks
    tasks: 'Tasks',
    new_task: 'New Task',
    summary: 'Summary',
    clone: 'Clone',
    description: 'Description',
    project: 'Project',
    issueType: 'Issue type',
    notify_subscribers: 'Notify subscribers',
    assignee: 'Assignee',
    reporter: 'Reporter',
    attachment: 'Attachment',
    visit_date: 'Visit Date',
    ///     custom fields
    customfield_10327: 'Customer',
    customfield_10336: 'Quote',

    //notes
    create_note: 'Create New Note',
    edit_note: 'Edit Note',
    // placeholders
    placeholder_titles: 'Choose title...',
    placeholder_gender: 'Choose gender...',
    placeholder_firstName: 'e.g. John',
    placeholder_lastName: 'e.g. Doe',
    placeholder_nationality: 'Nationality...',
    placeholder_position: 'e.g. Head of Sales',
    placeholder_facebook: 'https://example.com',
    placeholder_instagram: 'https://example.com',
    placeholder_linkedin: 'https://example.com',
    placeholder_businessPhone: '123-456-789',
    placeholder_mobile: '123-456-789',
    placeholder_businessEmail: 'example@email.com',
    placeholder_privateEmail: 'example@email.com',
    placeholder_street: 'e.g. Aargauerstrasse',
    placeholder_street_two: 'Street...',
    placeholder_city: 'e.g. Zürich',
    placeholder_postalCode: 'e.g. 8099',
    placeholder_medical_school: 'e.g. Medizinische Fakultät',
    placeholder_undergraduate: 'e.g. Medizinische Fakultät',
    placeholder_country: 'Country...',
    placeholder_state: 'e.g. Wyoming',
    //misc
    turnover: 'Sales by system',
    cant_login: "Can't login?",
    welcome_login: 'Login to CRM Bonebridge',
    enter_email: 'Enter your email address',
    sign_in: 'Sign in to your account',
    authenticate_message: 'Authenticate your account',
    enter_code: 'Enter code below',
    wait_for_code: "It may take a minute to receive your code. Haven't received it?",
    resend_code: 'Resend a new code',
    sent_confirm: 'Code is sent again!',
    page403_title: 'Verbotene Ressource',
    page403: "You don't have access to this page",
    page404_title: "Oops! You're lost.",
    page404: 'The page you are looking for was not found.',
    page500_title: 'Houston, we have a problem!',
    page500: 'The page you are looking for is temporarily unavailable.',
    //password
    reset_link: 'Send reset link',
    //errror messages
    error1: 'Something went wrong! Try again.',
    password_not_match: 'Passwords do not match! Try again.',
    code_error: 'Code must contain five digits',
    captcha_error: 'Captcha validation failed',
    first_name_error: 'Please provide a first name',
    last_name_error: 'Please provide a last name',
    social_url_error: 'Please provide url in format: https://example.com',
    phone_error: 'The number should not contain letters',
    business_email_error: 'Please enter email address in format: yourname@example.com',
    private_email_error: 'Please enter email address in format: yourname@example.com',
    //success messages
    message1:
        'Thank you for your request! An email with the instructions has been sent to your email',
    password_reseted: 'Password successfully changed! Please login with a new password.',
    repeat_password: 'Repeat New Password',
};
export default de;
