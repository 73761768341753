import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import API from '../api/auth';

export const AuthContext = React.createContext({
    user: {},
    isLoggedIn: false,
    logIn: (userData) => {},
    logOut: () => {},
});

const AuthContextProvider = ({ children }) => {
    const history = useHistory();
    // const { hash, pathname, search, state } = useLocation();
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const getLoggedInUser = async () => {
            try {
                const res = await API.getLoggedInUser();
                setUser(res.data);
            } catch (err) {
                console.error('error in fetching logged in user');
            }
        };

        getLoggedInUser();
    }, []);

    const logInHandler = (userData) => {
        setUser(userData);
        setIsLoggedIn(true);
        history.push('/');
    };

    const logOutHandler = async () => {
        try {
            await API.logOut();
            setUser(null);
            setIsLoggedIn(false);
            history.replace('/#/login');
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                isLoggedIn,
                logIn: logInHandler,
                logOut: logOutHandler,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
